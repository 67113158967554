import { bGet, get, post, bPost } from '@/scripts/http';
import { RTXYLoginParam, RTMLoginParam } from "./type/code-type";
import { Decrypt } from "@/scripts/utils";

export default {
    async listOrg() {
        const res = await get(`/FJRH.RTM/ORGService/GetUnifyOrgsSelect`, {}, "");
        return res;
    },
    async getCode(id: any) {
        const res = await get(`/${id}/V4/FJRH.Campus/UserService/GetValidateCode?codelength=4`, {}, "");
        return res;
    },
    async login(id: any, data: any, ex: any) {
        const res = await post(`/${id}/V4/FJRH.Campus/UserService/Login`, data, ex, "", "");
        return res;
    },
    async LoginForRyxWX(id: any, data: any, ex: any) {
        const res = await post(`/${id}/V4/FJRH.Campus/UserService/LoginForRyxWX`, data, ex, "", "");
        return res;
    },
    async GetModulePermissions(id: string, uid: string) {
        const res = await get(`/${id}/V4/FJRH.Campus/UserService/GetModulePermissions`, {
            "userid": uid
        }, "");
        return res;
    },
    // 获取手机号，关联RTM用户，及机构列表信息
    async GetRYXUserAndOrgInfo(data: any) {
        const res = await post(`/FJRH.RTM/OAuthService/GetRYXUserAndOrgInfo`, data)
        return res;
    },
    //创建并绑定RTM账号
    async CreateOrUpdateUser(data: any) {
        const res = await post(`/FJRH.RTM/UserService/CreateOrUpdateUserForMiniProject`, data)
        return res;
    },
    //绑定机构用户信息
    async BindOrgUser(data: any) {
        const res = await post(`/FJRH.RTM/UserService/BindOrgUser`, data)
        return res;
    },
    //通过RTM平台ID获取用户ID,没有就创建
    async CreateUserByRTM(id: any, data: any) {
        const res = await post(`/${id}/V4/FJRH.Campus/CourseService/CreateUserByRTM`, data)
        return res;
    },
    //获取绑定机构用户信息
    async GetDataByRTMUserID(id: any, data: any) {
        const res = await get(`/${id}/V4/FJRH.Campus/UserService/GetDataByRTMUserID`, data)
        return res;
    },
    //测试钉钉信息登录
    async UnifyDingLogin(data: any) {
        const res = await post(`/FJRH.RTM/OAuthService/UnifyDingLogin`, data);
        return res
    },
    //测试code
    async GetWXCode() {
        const res = await get(`/FJRH.RTM/OAuthService/GetWXCode`, { url: `http://schtest.fjrh.cn` });
        return res
    },
    async GetWxOpenID(code: any) {
        // const res = await get(`/FJRH.RTM/OAuthService/GetWxOpenID`, {code: code});
        const res = await get(`/FJRH.RTM/OAuthService/GetWxOpenID_PT`, { code: code });
        return res
    },
    async GetWXMiniAPPOpenID(code: any) {
        const res = await get(`/FJRH.RTM/OAuthService/GetWXMiniAPPOpenID`, { code: code });
        return res
    },
    //验证是否公众号进入睿云效是否绑定
    async PTXYUserCheck(openid: string, orgCode: string) {
        const res = await get(`/FJRH.RTM/RXYService/PTXYUserCheck`, { openid, orgCode });
        return res
    },
    //绑定服务号
    async BindOrgByLogin(data: RTXYLoginParam) {
        const res = await post(`/FJRH.RTM/RXYService/BindOrgByPTXYLogin`, data);
        return res
    },
    //绑定服务号
    async RTMByLogin(id: string, data: RTMLoginParam) {
        const res = await post(`/${id}/V4/FJRH.Campus/UserService/RTMLogin`, data);
        return res
    },
    // 定制学院获取token登录
    async AutoLogin(id: string, token: any) {
        const res = await get(`/${id}/V4/Service/AutoLogin`, { token });
        return res
    },
    async GetAccessToken() {
        const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/WXService/Getaccestoken`, {});
        // const res = await get(`/FJRH.RTM/OAuthService/getAccessToken`, {});
        return res
    },
    async WXJSApiSignature() {
        const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : "";
        const res = await post(`/FJRH.RTM/OAuthService/WXJSApiSignature`, { url: "https://schtest.fjrh.cn/loginh5" });
        // const res = await get(`/${ORGTOKEN}/V4/Service/WXJSApiSignature`, {url: "https://schtest.fjrh.cn/loginh5"});
        return res
    },
    async GetMjValidata(ticket: string, id: string) {
        // https://authserver.mju.edu.cn/authserver/serviceValidate?ticket=ST-990417-SAoue7VXgfJJ9GwDvI59-zfsoft.com&service=https://schtest.fjrh.cn/mjlogin
        const res = await post(`/${id}/V4/FJRH.Campus/UserService/CasUnifyLogin`, { ticket: ticket }, '', 'application/json; charset=UTF-8', "");
        return res
    }
}