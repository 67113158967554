
import { defineComponent } from "vue";
import { Icon, Toast, Dialog } from "vant";
import { Encrypt, Decrypt } from "../scripts/utils";
import apiLogin from "@/api/login";
import apiScanUnlock from "@/api/scanunlock";
import wx from "weixin-js-sdk";
import moment from "moment";
import * as dd from "dingtalk-jsapi";
import { Base64 } from "js-base64";
import protoRoot from "@/proto/proto";
import protobuf from "protobufjs";

export default defineComponent({
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      // userName: localStorage.getItem("UserInfo")
      //   ? JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserName
      //   : "未登录",
      permissions: [],
      // iframeShow: false,
      isShowRec: (window as any).Config.isShowRec,
      openid: "",
      miniOpenid: "",
      patrolTitle: "听巡课",
      isShowEval: true,
      isShowCenter: false,
      Lists: [
        {
          label: "教室管控",
          code: "Control",
          isShow: true,
          icon: require("@/assets/img/1.png"),
          path: "/classroom",
        },
        {
          label: "听巡课",
          code: "OnlinePatrol",
          isShow: true,
          icon: require("@/assets/img/xunke1.png"),
          path: "/patrol",
        },
        {
          label: "教室预约",
          code: "ClsRoomReservation",
          isShow: true,
          icon: require("@/assets/img/3.png"),
          path: "/subscribe/classroom/list",
        },
        {
          label: "预约管理",
          code: "ClsRoomAppt",
          isShow: true,
          icon: require("@/assets/img/7.png"),
          path: "/subscribe/management/list",
        },
        {
          label: "录播资源",
          code: "RecMaterial",
          isShow: true,
          icon: require("@/assets/img/4.png"),
          path: "/record/list",
        },
        {
          label: "我的课表",
          code: "TeacherSchedule",
          isShow: true,
          icon: require("@/assets/img/5.png"),
          path: "schedule/my",
        },
        {
          label: "课堂评价",
          code: "Eval",
          isShow: true,
          icon: require("@/assets/img/6.png"),
          path: "Supervision",
        },
        {
          label: "资产运维",
          code: "DeviceRepair",
          isShow: true,
          icon: require("@/assets/img/2.png"),
          path: "/property",
        },
        {
          label: "被评情况",
          code: "BeEval",
          isShow: true,
          icon: require("@/assets/img/9.png"),
          path: "Supervision",
        },
      ],
    };
  },
  computed: {
    userName() {
      if (localStorage.getItem("UserInfo")) {
        return JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserName;
      } else {
        return "未登录";
      }
    },
    Permissions() {
      return localStorage.getItem("Permissions")
        ? JSON.parse(localStorage.getItem("Permissions") as any)
        : [];
    },
  },
  watch: {
    $route: {
      handler(val: any) {
        let self = this as any;
        if (dd.env.platform != "notInDingTalk") {
          const userInfo = localStorage.getItem("UserInfo");
          if (!userInfo) {
            // 钉钉小程序获取code返回
            if (val.query.authCode) {
              const code = val.query.authCode;
              self.ToLogin(code);
            }
          }
        }
        if (val.query.orgtoken && val.query.miniCode) {
          const orgtoken = Encrypt(val.query.orgtoken);
          const miniCode = val.query.miniCode;
          localStorage.setItem("OrgCode", orgtoken);
          localStorage.setItem("minicode", miniCode);
          self.GetOpenID(val.query.orgtoken);
        }
        if (val.query.code) {
          self.GetOpenID();
        }
        if (val.query.type && val.query.type === "Jump") {
          localStorage.setItem("Token", val.query.Token);
          localStorage.setItem("OrgToken", val.query.OrgToken);
          localStorage.setItem("UserInfo", val.query.UserInfo);
          self.$nextTick(() => {
            self.getPermissions();
            self.toUnlock();
          });
        } else {
          if (val.query.qrcode) {
            self.$nextTick(() => {
              const userInfo = localStorage.getItem("UserInfo");
              if (userInfo) {
                self.analysisqrcode(decodeURI(val.query.qrcode));
              } else {
                const result = val.query.qrcode;
                let entrance;
                // 判断是否扫机构二维码
                if (result.indexOf("http") > -1) {
                  // const temp_result = result
                  //   .replace("http://schtest.fjrh.cn/", "")
                  //   .split("[");
                  // entrance = temp_result[0].replace("/", "");
                  const temp_result = result.split("[");
                  entrance =
                    temp_result[0][temp_result[0].lastIndexOf("/") - 1];
                }
                if (entrance && entrance == "b") {
                  self.analysisqrcode(decodeURI(val.query.qrcode));
                } else {
                  (wx as any).miniProgram.navigateTo({
                    url: `../Jump/Jump?frompage=home&qrcode=${val.query.qrcode}`,
                  });
                }
              }
            });
          }
        }
        //江夏学院首页功能列表调整
        if (localStorage.getItem("OrgToken")) {
          const org = Decrypt(localStorage.getItem("OrgToken"));
          if ((window as any).Config.OrgList.includes(org)) {
            // self.patrolTitle = "听巡课";
            // self.isShowEval = false;
            self.isShowCenter = true;
          }
        }
        if (
          localStorage.getItem("UserInfo") &&
          localStorage.getItem("Permissions")
        ) {
          const org = Decrypt(localStorage.getItem("OrgToken"));
          self.Lists.forEach((element: any) => {
            element.isShow = self.PermissionsManage(
              element.code,
              self.Permissions
            );
            //闽南师范学院去掉被评情况，我的课表
            if (element.code == "BeEval" || element.code == "TeacherSchedule") {
              element.isShow = org != "1279982605" && org != "1308637970"; //闽南师范 / 158
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 获取服务号openID
    async GetOpenID(org: any) {
      const url = window.location.href;
      if (url.indexOf("code") != -1) {
        const code = this.GetUrlParam("code");
        // console.log(code);
        if (code) {
          Toast.loading({
            duration: 0,
            forbidClick: true,
            message: "加载中",
          });
          const res = (await apiLogin.GetWxOpenID(code)) as any;
          if (res) {
            this.openid = res.Data.openid;
            localStorage.setItem("openid", this.openid);
            Toast.clear();
            this.UserCheck(res.Data.openid);
          }
        }
      } else {
        const reUrl = (window as any).Config.IsOnLine
          ? "https://schtest.fjrh.cn"
          : "http://schtest.fjrh.cn";
        const redirectUri = encodeURIComponent(reUrl); //发布上线后改https://schtest.fjrh.cn
        const ids = (window as any).Config.BindWX.filter((ele: any) => {
          return ele.orgid === org;
        });
        const appid = ids[0].APPID;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${org}#wechat_redirect`;
      }
    },
    async UserCheck(openid: any) {
      const orgCode = localStorage.getItem("OrgCode")
        ? Decrypt(localStorage.getItem("OrgCode"))
        : "";
      const res = (await apiLogin.PTXYUserCheck(openid, orgCode)) as any;
      if (res.Result) {
        this.$router.push("/rtmlogin?rtmid=" + res.Data);
      } else {
        this.$router.push("/login?state=rtm");
      }
    },
    //获取微信端openid
    async GetMiniOpenID(code: string) {
      const res = (await apiLogin.GetWXMiniAPPOpenID(code)) as any;
      if (res) {
        this.miniOpenid = res.Data.openid;
      }
    },
    // 获取参数
    GetUrlParam(name: any) {
      var url = window.location.href;
      let params = url.substr(url.lastIndexOf("?") + 1).split("&");
      for (let i = 0; i < params.length; i++) {
        let param = params[i];
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        if (key === name) {
          return value;
        }
      }
    },
    toAnothers(path: string, code: string) {
      let self = this as any;
      const userInfo = localStorage.getItem("UserInfo");
      const timeStamp = new Date().getTime();
      if (userInfo) {
        if (code === "todaySchedule") {
          // window.open(`https://m.mytest.fjrh.cn/`, "_self");
          // (this as any).$refs.my_space.contentWindow.postMessage(
          //   {
          //     Token: localStorage.getItem("Token"),
          //     OrgToken: localStorage.getItem("OrgToken"),
          //     UserInfo: localStorage.getItem("UserInfo"),
          //     path: path,
          //   },
          //   "https://m.mytest.fjrh.cn/"
          // );
          window.open(
            `https://m.mytest.fjrh.cn/#${localStorage.getItem(
              "Token"
            )},${localStorage.getItem("OrgToken")},${localStorage.getItem(
              "UserInfo"
            )},${path}?t=${timeStamp}`,
            "_self"
          );
        } else {
          if (code == "BeEval") {
            window.open(
              `https://m.mytest.fjrh.cn/#${localStorage.getItem(
                "Token"
              )},${localStorage.getItem("OrgToken")},${localStorage.getItem(
                "UserInfo"
              )},${path}?type=2&t=${timeStamp}`,
              "_self"
            );
            return;
          }
          if (self.PermissionsManage(code, self.Permissions)) {
            window.open(
              `https://m.mytest.fjrh.cn/#${localStorage.getItem(
                "Token"
              )},${localStorage.getItem("OrgToken")},${localStorage.getItem(
                "UserInfo"
              )},${path}?t=${timeStamp}`,
              "_self"
            );
            // window.open(`https://m.mytest.fjrh.cn/`, "_self");
            // (this as any).$refs.my_space.contentWindow.postMessage(
            //   {
            //     Token: localStorage.getItem("Token"),
            //     OrgToken: localStorage.getItem("OrgToken"),
            //     UserInfo: localStorage.getItem("UserInfo"),
            //     path: path,
            //   },
            //   "https://m.mytest.fjrh.cn/"
            // );
          } else {
            Toast.fail("您当前没有相应权限，无法查看");
          }
        }
      } else {
        // self.$router.push("/login");
        (wx as any).miniProgram.navigateTo({
          url: `../Jump/Jump?frompage=home`,
        });
      }
    },
    toPage(url: string, code: string) {
      if (code == "TeacherSchedule" || code == "Eval" || code == "BeEval") {
        this.toAnothers(url, code);
      } else {
        this.linkTo(url, code);
      }
    },
    linkTo(url: string, code: string) {
      let self = this as any;
      const userInfo = localStorage.getItem("UserInfo");
      if (userInfo) {
        // self.$router.push(url);
        if (
          self.PermissionsManage(code, self.Permissions) ||
          code === "ClsRoomReservation"
        ) {
          const path =
            code == "OnlinePatrol" ? `${url}?title=${self.patrolTitle}` : url;
          self.$router.push(path);
        } else {
          Toast.fail("您当前没有相应权限，无法查看");
        }
      } else {
        // self.$router.push("/login");
        (wx as any).miniProgram.navigateTo({
          url: `../Jump/Jump?frompage=home&path${url}&code${code}`,
        });
      }
    },
    ToLogin(code: any) {
      // Toast(code);
      let self = this as any;
      self.$router.push({
        path: "/auth/ding",
        query: {
          authCode: code,
          state: "dingding",
        },
      });
    },
    // 钉钉H5应用获取code
    DDLogin(corpId: any) {
      let self = this as any;
      if (corpId) {
        dd.runtime.permission
          .requestAuthCode({
            corpId: corpId, //企业corpid
          })
          .then(
            (onSuccess: any) => {
              const authCode = onSuccess.code;
              self.$router.push({
                path: "/auth/ding",
                query: {
                  authCode: authCode,
                  state: "dingding",
                },
              });
            },
            (onFail: any) => {
              Toast("Code获取失败");
            }
          );
      }
    },
    async wxScanUnlock() {
      let self = this as any;
      const userInfo = localStorage.getItem("UserInfo");
      if (userInfo) {
        if (
          self.$store.state.SrvConfigInfo &&
          self.$store.state.SrvConfigInfo.RYXOpenScanSet === false
        ) {
          if (dd.env.platform != "notInDingTalk") {
            self.$router.push("/ddMessage?frompage=scan&scanType=u");
          } else {
            (wx as any).miniProgram.navigateTo({
              url: "../scan/index?scanType=u",
            });
          }
          return;
        }
        const res: any = await apiScanUnlock.GetData({
          userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
          actionTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        });
        if (res) {
          if (res.Data) {
            self.$router.push({
              name: "AfterClass",
              query: {
                sno: res.Data.serialNo,
                sulid: res.Data.SULID,
                planid: res.Data.planid,
                planname: res.Data.planname ? res.Data.planname : "",
              },
            });
          } else {
            if (dd.env.platform != "notInDingTalk") {
              self.$router.push("/ddMessage?frompage=scan&scanType=u");
            } else {
              (wx as any).miniProgram.navigateTo({
                url: "../scan/index?scanType=u",
              });
            }
            // self.$router.push({
            //   name: "AfterClass",
            //   query: {
            //     sno: "CPC3600JZt212321",
            //     uparam: JSON.stringify([
            //       {
            //         route: "/fixQRDetailed",
            //         id: "18090618463429790200845025491299",
            //         sno: "JYDM000006",
            //         BrandCode: "JYD",
            //         type: "1",
            //         SchoolID: "18090609323669495301908099555966",
            //         DogID: "1308637970"
            //       }
            //     ])
            //   }
            // });
          }
        }
        // console.log("getdata", res);
      } else {
        // self.$router.push("/login");
        if (dd.env.platform != "notInDingTalk") {
          self.$router.push("/ddMessage?frompage=home");
        } else {
          (wx as any).miniProgram.navigateTo({
            url: `../Jump/Jump?frompage=home`,
          });
        }
      }
    },
    wxLinkTo() {
      let self = this as any;
      const userInfo = localStorage.getItem("UserInfo");
      if (userInfo) {
        if (dd.env.platform != "notInDingTalk") {
          self.$router.push("/ddMessage?frompage=scan&scanType=c");
        } else {
          (wx as any).miniProgram.navigateTo({
            url: "../scan/index?scanType=c",
          });
        }
      } else {
        // self.$router.push("/login");
        if (dd.env.platform != "notInDingTalk") {
          self.$router.push("/ddMessage?frompage=home");
        } else {
          (wx as any).miniProgram.navigateTo({
            url: `../Jump/Jump?frompage=home`,
          });
        }
      }
    },
    analysisqrcode(result: string) {
      let self = this as any;
      if (result.indexOf("http") > -1) {
        // const temp_result = result
        //   .replace("http://schtest.fjrh.cn/", "")
        //   .split("[");
        // const entrance = temp_result[0].replace("/", "");
        const temp_result = result.split("[");
        const entrance = temp_result[0][temp_result[0].lastIndexOf("/") - 1];
        let data = {};
        if (entrance == "u" && temp_result[1].indexOf("BrandCode") < 0) {
          result = temp_result[1];
        } else {
          result = "[" + temp_result[1];
        }
        let json_result = null;

        if (entrance !== "v") {
          try {
            json_result = JSON.parse(result);
            console.log(json_result);
            if (entrance == "u") {
              data = {
                _type: json_result[0].type,
                serialNo: json_result[0].sno,
                uparam: result,
              };
            } else if (entrance == "b") {
              data = {
                id: json_result[0].id,
                _type: json_result[0].type,
                uparam: result,
              };
            } else {
              data = {
                id: json_result[0].id,
                _type: json_result[0].type,
              };
            }
          } catch (err) {
            var resultstr = this.hexCharCodeToStr(result);
            if (this.isJson(resultstr)) {
              json_result = JSON.parse(resultstr);
            } else {
              resultstr = this.analysisBPCode(result);
              json_result = JSON.parse(resultstr);
            }
            data = {
              _type: json_result.type,
              serialNo: json_result.serialNo ? json_result.serialNo : "",
              uparam: resultstr,
            };
          }
        }
        if ((data as any)._type == undefined) {
          Toast.fail("扫码失败");
        } else {
          if (entrance !== "v" && entrance !== "u" && entrance !== "b") {
            if (
              json_result[0].DogID !== Decrypt(localStorage.getItem("OrgToken"))
            ) {
              self.$router.push("/login");
              return;
            }
          }
          let options: any;
          const orgId = localStorage.getItem("OrgToken")
            ? Decrypt(localStorage.getItem("OrgToken"))
            : "";
          switch (entrance) {
            case "c":
            case "d":
              self.$router.push({
                name: "Create",
                params: {
                  data: JSON.stringify(data),
                },
              });
              break;
            case "u":
              // self.$router.push({
              //   name: "Unlock",
              //   params: {
              //     data: (data as any).uparam
              //   }
              // });
              options = JSON.parse((data as any).uparam);
              if ((data as any).uparam.includes("BrandCode")) {
                if (orgId == options[0].DogID) {
                  self.$router.push({
                    name: "AfterClass",
                    query: {
                      sno: (data as any).serialNo,
                      uparam: (data as any).uparam,
                    },
                  });
                } else {
                  localStorage.setItem("scanData", JSON.stringify(data));
                  (wx as any).miniProgram.navigateTo({
                    url: `../login/login?schoolID=${options[0].SchoolID}&DogID=${options[0].DogID}&state=u`,
                  });
                }
              } else {
                self.$router.push({
                  name: "AfterClass",
                  query: {
                    sno: (data as any).serialNo,
                    uparam: (data as any).uparam,
                  },
                });
              }
              break;
            case "b":
              options = JSON.parse((data as any).uparam);
              // if (orgId == options[0].DogID) {
              //   self.$router.push("/");
              //   return;
              // }
              (wx as any).miniProgram.navigateTo({
                url: `../login/login?schoolID=${options[0].SchoolID}&DogID=${options[0].DogID}`,
              });
              break;
            default:
              self.$router.push({
                name: "Create",
                params: {
                  data: JSON.stringify(data),
                },
              });
              break;
          }
        }
      }
    },
    hexCharCodeToStr(hexCharCodeStr: any) {
      var trimedStr = hexCharCodeStr.trim();
      var rawStr =
        trimedStr.substr(0, 2).toLowerCase() === "0x"
          ? trimedStr.substr(2)
          : trimedStr;
      var len = rawStr.length;
      if (len % 2 !== 0) {
        console.log("Illegal Format ASCII Code!");
        return "";
      }
      var curCharCode;
      var resultStr = [];
      for (var i = 0; i < len; i = i + 2) {
        curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
        resultStr.push(String.fromCharCode(curCharCode));
      }
      if (resultStr[resultStr.length - 2] == ",") {
        resultStr.splice(resultStr.length - 2, 1);
      }
      return resultStr.join("");
    },
    // 解析扫码protobuf
    analysisBPCode(str: any) {
      const rawResponse = Base64.toUint8Array(str) as any;
      const PBMessageResponse = protoRoot.lookup(`Scan.CtrlScanInfo`) as any;
      const buf = protobuf.util.newBuffer(rawResponse) as any;
      const result = PBMessageResponse.decode(buf) as any;
      console.log(result);
      return JSON.stringify(result);
    },
    isJson(str: any) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log("error：" + str + "!!!" + e);
          return false;
        }
      }
    },
    PermissionsManage(code: string, arr: any[]) {
      let self = this as any;
      if (arr && arr.length === 0) {
        return false;
      }
      for (let index in arr) {
        let child = (arr[index] as any).SubModules;
        if ((arr[index] as any).ModuleCode !== code) {
          if (!self.PermissionsManage(code, child)) {
            continue;
          }
        }
        return true;
      }
      return false;
    },
    async getPermissions() {
      const Permissions = (await apiLogin.GetModulePermissions(
        Decrypt(localStorage.getItem("OrgToken")),
        JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID
      )) as any;
      if (Permissions.Result) {
        localStorage.setItem("Permissions", JSON.stringify(Permissions.Datas));
      }
    },
    loginOut() {
      const userInfo = localStorage.getItem("UserInfo");
      if (userInfo) {
        Dialog.confirm({
          message: "是否确认退出登录",
          confirmButtonColor: "#2F7DFF",
        }).then(() => {
          const orgId = localStorage.getItem("OrgToken")
            ? Decrypt(localStorage.getItem("OrgToken"))
            : "";
          this.clearToken();
          if (orgId == "343670966") {
            let urls = `https://authserver.mju.edu.cn/authserver/logout?service=https://schtest.fjrh.cn/logout`;
            window.open(urls, "_self");
          }
          if (orgId == "61391220") {
            let urls = `http://authserver.acvtc.edu.cn/authserver/logout?service=https://schtest.fjrh.cn/logout`;
            window.open(urls, "_self");
          }
          if (dd.env.platform != "notInDingTalk") {
            window.location.href = "/";
          }
          (wx as any).miniProgram.navigateTo({
            url: `../index/index?state=loginout`,
          });
        });
      } else {
        this.$router.push("/login");
      }
    },
    toUnlock() {
      let self = this as any;
      const scanData = localStorage.getItem("scanData");
      if (scanData) {
        const data = JSON.parse(scanData);
        self.$router.push({
          name: "AfterClass",
          query: {
            sno: (data as any).serialNo,
            uparam: (data as any).uparam,
          },
        });
      }
    },
    clearToken() {
      localStorage.removeItem("Token");
      localStorage.removeItem("OrgToken");
      localStorage.removeItem("UserInfo");
      localStorage.removeItem("Permissions");
    },
    toPerson() {
      this.$router.push("/person");
    },
  },
});
