import { get } from "@/scripts/http";
import { Decrypt } from "@/scripts/utils";
import { GetDataParam, GetLessonBySnoParam, SaveParam, UpdatePlanEstateParam } from "./type/scanunlock-type";

export default {
  ServiceURL: `/ScanUnlockLog`,
  //查询数据 判断是否进入下课时间设置页面
  async GetData(data: GetDataParam) {
    const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
    return get(`/${ORGTOKEN}/V4${this.ServiceURL}/GetData`, data);
  },
  async GetLessonBySno(data: GetLessonBySnoParam) {
    const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
    return get(`/${ORGTOKEN}/V4${this.ServiceURL}/GetLessonBySno`, data);
  },
  async Save(data: SaveParam) {
    const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
    return get(`/${ORGTOKEN}/V4${this.ServiceURL}/Save`, data);
  },
  async UpdatePlanEstate(data: UpdatePlanEstateParam) {
    const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
    return get(`/${ORGTOKEN}/V4${this.ServiceURL}/UpdatePlanEstate`, data);
  }
}